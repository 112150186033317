// ----------------------------------------------------------------------

const ROOTS = {
  LANDING: '/',
  ARTICLES: '/articles',
  POLICY: '/policies',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  SUBSCRIPTION: '/subscription',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  landing: {
    root: ROOTS.LANDING,
  },
  policies: {
    root: ROOTS.POLICY,
    terms: `${ROOTS.POLICY}/terms`,
  },
  articles: {
    root: ROOTS.ARTICLES,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    one: `${ROOTS.DASHBOARD}/one`,
    two: `${ROOTS.DASHBOARD}/two`,
    three: `${ROOTS.DASHBOARD}/three`,
    campaign: {
      root: `${ROOTS.DASHBOARD}/campaign`,
      new: `${ROOTS.DASHBOARD}/campaign/new`,
      details: (id) => `${ROOTS.DASHBOARD}/campaign/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/campaign/${id}/edit`,
    },
    subscription: {
      root: `${ROOTS.DASHBOARD}/subscription`,
    },
    members: {
      root: `${ROOTS.DASHBOARD}/members`,
    },
    tracking: {
      root: `${ROOTS.DASHBOARD}/tracking`,
      details: (id) => `${ROOTS.DASHBOARD}/tracking/${id}/details`,
      dashboard: (id, forceReload = false) =>
        `${ROOTS.DASHBOARD}/tracking/${id}/dashboard${forceReload ? `?rl=${+Date.now()}` : ''}`,
      config: (id) => `${ROOTS.DASHBOARD}/tracking/${id}/config`,
      googleAdsConnector: (id) => `${ROOTS.DASHBOARD}/tracking/${id}/connector/google-ads`,
    },
    explore: {
      root: `${ROOTS.DASHBOARD}/explore`,
      details: (id) => `${ROOTS.DASHBOARD}/explore/${id}/details`,
    },
    article: {
      root: `${ROOTS.DASHBOARD}/article`,
      new: `${ROOTS.DASHBOARD}/article/new`,
      details: (id) => `${ROOTS.DASHBOARD}/article/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/article/${id}/edit`,
    },
    partner: {
      root: `${ROOTS.DASHBOARD}/partner`,
      new: `${ROOTS.DASHBOARD}/partner/new`,
      details: (id) => `${ROOTS.DASHBOARD}/partner/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/partner/${id}/edit`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
    },
    account: {
      root: `${ROOTS.DASHBOARD}/account`,
    },
    monitor: {
      root: `${ROOTS.DASHBOARD}/monitor`,
    },
    errors: {
      root: `${ROOTS.DASHBOARD}/errors`,
    },
  },
  documentation: {
    root: `https://docs.tracklution.com/`,
  },
};
